

.bridgeSelectChain:hover{
    background-color: #1afba9 !important;
    color: black !important;
    min-height: 40px !important;
    border: 0px  !important;
    border-style: solid !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 20px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 20px !important;
    padding-bottom: 1em;
    padding-right: 0px !important;
    
}
.bridgeSelectChain{
    color: white !important;
    min-height: 40px !important;
    border: 0px  !important;
    border-style: solid !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 20px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 20px !important;
    padding-bottom: 1em;
    padding-right: 0px !important;
    
}

.bridgeSelectToken:hover{
    background-color: #1afba9 !important;
    color: black !important;
    min-height: 40px !important;
    border-style: solid !important;
    border: 0px  !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 0px !important;
    padding-bottom: 1em;
    padding-right: 0px !important;
}
.bridgeSelectToken{
    color: white !important;
    min-height: 40px !important;
    border-style: solid !important;
    border-color: #fa2aa0  !important;
    border: 0px;
    border-right: 0px !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 0px !important;
    padding-bottom: 1em;
    padding-right: 1px !important;
}

.bridgeSmallHeader{
    display: flex;
	flex-direction: row;
	align-items: center;
    font-size: 0.8em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}    

.bridgeInboxHeader{
    margin-bottom: 0.0em;
    margin-top: 0em;
    width: 100% !important;
}

.bridgeAlignRight{
    text-align: right !important;
    padding-right: 0px !important;
}

.bridgeInputCustom{
    font-size: 1.4em !important;
    width: 100%;
    background-color: #0b0b0b !important;
    color: white;
  }
  .bridgeInputCustom:focus{
    outline: 0;
  }

  .bridgeTokenBalance{
    text-align: right;
  }

  .bridgeRepeat{
    margin-top: 17px !important;
    padding-top: 0px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    text-align: center;
  }

  .bridgeTokenName{
    font-size: 0.8em;
  }

  .bridgeSubBox{
    border: 2px  !important;
    border-style: solid !important;
    border-color: #fa2aa0  !important;
    border-radius:  20px !important;
    background-color: #0b0b0b !important;
    text: white;
    margin-bottom: 0px;
    margin: 0px !important;
  }